<template>

  <div class="body">

    <div id="app">

      <section class="top-header" v-bind:class="canStick()" v-show="current_page != 'casinogames'">

        <div class="d-flex justify-content-between deposit_page"
          v-if="current_page == 'deposit' && current_page != 'casinogames'">
          <div class="d-flex justify-around align-center">
            <div class="s-backButton btn" @click="goBack"><i class="bi bi-chevron-left"></i> Back</div>
          </div>
          <div class="d-flex justify-around">
            <div @click="goTo('deposit-how-to')" class="mr-3" style="font-size:13px"><i
                class="bi bi-question-circle d-none"></i>How Do I Deposit</div>
            <div @click="goTo('home')"><i class="bi bi-house-door-fill"></i></div>
          </div>
        </div>
        <div class="row p-1 m-1" style="background-color: var(--darkBackground); border-bottom: 0.05em solid #ffffff47;"
          v-show="current_page != 'deposit' && current_page != 'casinogames'">

          <span class="col d-flex align-items-center justify-content-start">
            <router-link style="margin-left: -15px;" to="/">
              <img src="/powerbet.png" class="top-header-logo img-fluid logo-img" alt="Powerbet Logo">
            </router-link>
          </span>

          <!-- <router-link to="/" class="d-none d-md-block">
            <button style="background-color: var(--yellow);width: 300px;height: 40px;" class="btn">
              <img style="width: 14px;" src="/img/menu/download.png" />
              Get mobile app
            </button>
          </router-link> -->
          <div class="col d-flex align-items-center justify-content-end">
            <router-link to="/gifts">
              <button style="background-color: #7a00cb;" class="btn btn-primary mr-2 d-none d-md-block">
                <img style="width: 14px;" src="/megaphone.png" />
                Promotions
              </button>
            </router-link>

            <div v-if="profile" class="d-flex depo text-center justify-content-around">
              <router-link to="/my-account">
                <img loading="lazy" src="img/icon/new_wallet.png" class="wallet-icon"> <br>
                <span style="font-size: medium;" class="wallet-balance text-yellow wallet-text">
                  <strong>KSH. <span v-text="bal"></span></strong>
                </span>
              </router-link>
            </div>

            <div style="margin-right: -30px;" v-else class="d-flex depo text-right align-items-center">
              <router-link style="background-color: var(--yellow); margin-right: -2px;" v-if="!profile" to="/login"
                class="btn header-login-button">
                <span style="color: var(--darkBackground); width: 30px;">
                  LOGIN
                </span>
              </router-link>
              <router-link v-if="!profile" to="/join" class="btn header-login-button">
                <span style="color: var(--yellow); width: 30px; font-weight: 700;">
                  REGISTER
                </span>
              </router-link>
            </div>

            <div style="margin-right: -25px;" class="d-flex depo text-right align-items-center">
              <a class="" style="margin-left: 40px; margin-right: 10px;" @click="showMenu">
                <img style="margin-left: -10px; width: 24px;" src="/img/icon/icons-menu.png" />
              </a>
            </div>
          </div>
        </div>

      </section>

      <!--A-Z Menu-->
      <div id="menu-modal" class="faso-menu-modal"
        style="z-index: 99999;width: 350px; position: fixed; right: 0;border-left: solid 2px var(--yellow);height: 100%;">
        <!-- Modal content -->
        <div class="faso-menu-modal-content">
          <section style="float: right;" class="w-100 menu-bg">
            <div class="row m-0 p-0 side-menu-header">
              <div class="col-6 text-center">
                <router-link to="/" @click.native="closeSidebar">
                  <img style="margin-top: 8px;width: 100px;float: left;border-radius: 5px;" loading="lazy"
                    src="/powerbet.png" class="top-header-logo pt-3">
                </router-link>
              </div>
              <div class="col-6 text-right m-0 p-0">
                <div class="p-3">
                  <strong class="text-light faso-menu-close">
                    <img style="margin-left: -10px; width: 24px;" src="/cancel.png" @click="closeSidebar" />
                  </strong>
                </div>
              </div>
            </div>
            <hr class="m-0">
            <div style="margin-top: 10px;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link class="faso-menu-close" to="/my-account" @click.native="closeSidebar">
                <a class="flat-link">
                  <img style="width: 35px;height: 35px;" loading="lazy" src="/img/menu/icon-user.png"
                    class="side-icon-svg mr-2">
                  <strong>
                    <span class="text-blue">
                      ACCOUNT
                    </span>
                  </strong>
                </a>
              </router-link>
            </div>
            <hr class="m-0">
            <div style="margin-top: 10px;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link to="/gifts" @click.native="closeSidebar">
                <button style="background-color: #7a00cb;width: 300px;height: 60px;" class="btn btn-primary">
                  <img style="width: 14px;" src="/megaphone.png" />
                  <strong>
                    Promotions
                  </strong>
                </button>
              </router-link>
            </div>
            <hr class="m-0">
            <div style="margin-top: 10px;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click.native="closeSidebar" href="/app/powerBet.apk">
                <button style="background-color: var(--yellow);width: 300px;height: 60px;" class="btn">
                  <img style="width: 24px;" src="/img/menu/download.png" />
                  <strong>
                    Get mobile app
                  </strong>
                </button>
              </a>
            </div>
            <hr class="m-0">
            <div style="margin-top: 10px;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link to="/my-account" @click.native="closeSidebar">
                <button style="background-color: var(--yellow);width: 300px;height: 60px;" class="btn">
                  <img style="width: 24px;" src="/img/menu/dollar.png" />
                  <strong>
                    Referral Program
                  </strong>
                </button>
              </router-link>
            </div>
            <hr class="m-0">
            <div style="margin-top: 10px;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <span class="text-blue">FUNDS</span>
            </div>
            <hr class="m-0">
            <div style="margin-top: 10px;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link to="/my-account" @click.native="closeSidebar">
                <a class="flat-link">
                  <img style="width: 25px;height: 25px;" loading="lazy" src="/img/menu/icon-transaction.png"
                    class="side-icon-svg mr-2">
                  <strong>
                    <span class="text-blue">Transactions</span>
                  </strong>
                </a>
              </router-link>
            </div>
            <hr class="m-0">
            <div style="margin-top: 10px;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link to="/my-account" @click.native="closeSidebar">
                <a class="flat-link">
                  <img style="width: 25px;height: 25px;" loading="lazy" src="/img/menu/icon-deposit.png"
                    class="side-icon-svg mr-2">
                  <strong>
                    <span class="text-blue">Deposit Funds</span>
                  </strong>
                </a>
              </router-link>
            </div>
            <hr class="m-0">
            <div style="margin-top: 10px;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link to="/my-account" @click.native="closeSidebar">
                <a class="flat-link">
                  <img style="width: 25px;height: 25px;" loading="lazy" src="/img/menu/icon-withdraw.png"
                    class="side-icon-svg mr-2">
                  <strong>
                    <span class="text-blue">Withdraw Funds</span>
                  </strong>
                </a>
              </router-link>
            </div>
            <hr class="m-0">
            <div v-if="profile" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="logout" class="flat-link">
                <img loading="lazy" src="/menu/logout.png" class="side-icon-svg mr-2">
                <span class="text-danger">Logout</span>
              </a>
            </div>
          </section>
        </div>
      </div>


      <!--End A-Z Menu-->

      <span id="menu-init" class="hidden"></span>

      <span class="faso-menu-close hidden"></span>

      <router-view></router-view>

      <div class="mb-0">
        <div class="bottom-fixed-bar" ref="bottomBar">
          <slot></slot>
          <div class="bottom-navigation">
            <router-link class="nav-item" to="/">
              <div class="icon">
                <img src="/img/icons8-home.png" class="menu-icon-svg">
              </div>
              <strong>
                <span style="color: white;" class="item-name">Home</span>
              </strong>
            </router-link>

            <router-link to="/my-account" class="nav-item">
              <div class="icon">
                <img src="/img/icons8-money.png" class="menu-icon-svg">
              </div>
              <strong>
                <span style="color: white;" class="item-name">Transactions</span>
              </strong>
            </router-link>

            <a href="/app/powerBet.apk" class="nav-item">
              <div class="icon">
                <img src="/img/icons8-android.png" class="menu-icon-svg">
              </div>
              <strong>
                <span style="color: white;" class="item-name">Lite App</span>
              </strong>
            </a>

            <router-link to="/gifts" class="nav-item">
              <div class="icon">
                <img src="/img/icons8-speaker.png" class="menu-icon-svg">
              </div>
              <strong>
                <span style="color: white;" class="item-name">Promotions</span>
              </strong>
            </router-link>
          </div>
        </div>
      </div>

      <div class="depo-widget d-none" id="dpo-widg" v-show="current_page != 'deposit'">
        <span id="dpo-close" class="text-light" @click="closeWidget()"><i class="bi bi-x-lg"></i></span>
        <router-link to="/deposit" class="deposit-link" v-show="current_page != 'deposit'">
          <img loading="lazy" src="img/depo.webp" class="depo-widg">
        </router-link>
      </div>

      <div id="footer" class="p-3" v-if="current_page != 'jackpot' && current_page != 'casinogames'">

        <div class="eitin text-center mb-4">
          <img loading="lazy" src="img/18+.png" alt="">
        </div>

        <div class="eitin text-center mb-4">
          <a style="font-size: medium;" href="tel:0746444222" class="footer-link disclaimer-text p-2">
            <span style="color: #F7B413;"> <img style="width: 20px;height: 20px;" src="/img/call.png"> :
              0746444222</span>
          </a>
        </div>

        <div class="term text-center disclaimer-text">
          <!-- <a href="tel:+254 723 447338" class="footer-link disclaimer-text p-2">
              <i class="bi bi-phone"></i> +254 723 447338
            </a>
            | -->
          <a href="/responsible-gaming" class="footer-link disclaimer-text p-2">
            <span style="color: #F7B413;">Responsible Gaming</span>
          </a>
          |
          <a href="/terms" class="footer-link disclaimer-text p-2">
            <span style="color: #F7B413;">Terms and Conditions</span>
          </a>

        </div>
        <hr>
        <div class="liii text-center">
          <a href="https://www.facebook.com/Powerbet" class="footer-link title-text p-2">
            <i class="bi bi-facebook"></i>
          </a>
          <a href="https://twitter.com/PowerbetKE" class="footer-link title-text p-2">
            <i class="bi bi-twitter"></i>
          </a>
          <a href="https://www.instagram.com/Powerbet" class="footer-link title-text p-2">
            <i class="bi bi-instagram"></i>
          </a>
        </div>

      </div>

    </div>

  </div>

</template>

<style scoped>
.logo-img {
  max-width: 100px;
  width: auto;
  height: auto;
  border-radius: 10px;
}

.bottom-fixed-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: none;
  /* Hidden by default */
  z-index: 1000;
}

.bottom-navigation {
  display: flex;
  justify-content: space-around;
  /* Evenly space items across the width */
  padding: 10px 0;
}

.nav-item {
  text-align: center;
  flex: 1;
}

.menu-icon-svg {
  width: 24px;
  height: 24px;
}

/* Media query to show navigation only on mobile devices */
@media (max-width: 768px) {
  .bottom-fixed-bar {
    display: block;
  }
}
</style>

<script>
// import jQuery from "jquery";
// const $ = jQuery;


import mqtt from "mqtt";
import axios from "@/services/api";
import casino from "@/services/casino";
import store from './store/store';
// import Header from './components/Header.vue';

export default {
  store,
  name: 'app',
  components: {
    // Header

  },
  mounted: function () {

    var vm = this;

    this.initMenuModal();

    // vm.autoRefreshUI(vm.$vnode.tag);
    vm.myProfile = vm.getProfile();

    vm.reloadProfile();

    this.EventBus.$on('deposit:popup', function (payload) {
      vm.amount = payload.amount;
      vm.message = payload.message;
      if (document.getElementById("deposit-init") != null) {
        document.getElementById("deposit-init").click();
      }

    });

    this.EventBus.$on('init:mqtt', function () {

      console.log('Wants Inititalize MQTT');

      if (vm.mqttClient !== false) {

        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();

    });

    // this.getCategories();

    vm.initMqtt();

    var params = this.getURLParameters();

    var utm_source = params.utm_source ? params.utm_source : ''
    var utm_medium = params.utm_medium ? params.utm_medium : ''
    var utm_campaign = params.utm_campaign ? params.utm_campaign : ''
    var referral_code = params.p ? params.p : ''
    var referrer = document.referrer
    var btag = params.btag ? params.btag : ''

    if (btag.length > 0) {

      this.setValue('btag', btag)

    }

    if (utm_source.length > 0) {

      this.setValue('utm_source', utm_source)
    }

    if (utm_medium.length > 0) {

      this.setValue('utm_medium', utm_medium)
    }

    if (referral_code.length > 0) {

      this.setValue('referral_code', referral_code)
    }

    if (utm_campaign.length > 0) {

      this.setValue('utm_campaign', utm_campaign)
    }

    //if(referrer.length > 0 ) {

    this.setValue('referrer', referrer)

    // }

    window.addEventListener('message', function (e) {
      // Get the sent data
      const data = e.data;

      if (data.name !== undefined && data.name === "login") {

        vm.$router.push({ name: 'login', params: { action: 'login' } });


      }

    });



  },
  computed: {
    showAppBanner: function () {
      if (this.$store.state.show_app_banner) {
        return '';
      } else {
        return 'd-none';
      }
    },
    bonus: function () {

      return this.formatCurrency(this.$store.state.bonusBalance);

    },

    bal: function () {

      return this.formatCurrency(this.$store.state.walletBalance);

    },

    full_name: function () {

      if (this.profile === undefined) {

        return ""
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {

        return ms;

      }

      var fn = this.profile.first_name;
      var mn = this.profile.middle_name;
      var ln = this.profile.last_name;
      var ms = this.profile.msisdn;
      return fn + ' ' + mn + ' ' + ln + ' - ' + ms;

    },
    profile: function () {

      return this.getProfile();

    },

    current_page: function () {

      return this.$store.state.current_page;

    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {

        if (v.sport_id > 1) {

          t.push(v);
        }

      })

      return t;
    },
    homePageFontColor: function () {

      if (this.current_page === 'home') {

        return "yellow-txt";
      }

      return "";

    },
    livePageFontColor: function () {

      if (this.current_page === 'live') {

        return "yellow-txt";
      }

      return "";

    },
    historyPageFontColor: function () {

      if (this.current_page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip: function () {

      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip
    },
    activeBets: function () {

      var p = this.getProfile();

      if (!p) {

        return 0;

      }

      return p.b

    },
    betslip_count: function () {

      if (!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },

  },
  methods: {

    closeSidebar() {
      document.getElementById('menu-modal').style.display = 'none';
    },

    getReferrer() {
      return document.referrer || "No referrer found";
    },

    getWidth: function (div) {

      if (this.profile) {

        if (div === "gift") {

          return "col-3"

        }

        if (div === "logo") {

          return "col-6"

        }

        if (div === "wallet") {

          return "col-3"

        }

      }

      if (this.current_page === "join") {

        if (div === "logo") {

          return "col-12"

        }

        return "d-none"
      }

      if (div === "gift") {

        return "d-none"

      }

      if (div === "logo") {

        return "col-7"

      }

      if (div === "wallet") {

        return "col-5"

      }

    },

    canStick: function () {

      if (this.$store.state.current_page == 'casinogames' || this.$store.state.current_page == 'virtuals') {
        return '';

      } else {

        return 'header-stick';

      }

    },
    canFeatureStick: function () {
      if (this.$store.state.current_page == 'match' || this.$store.state.current_page == 'casinogames' || this.$store.state.current_page == 'casino' || this.$store.state.current_page == 'casino-live' || this.$store.state.current_page == 'virtuals') {
        return '';
      } else {
        return 'feature-stick';
      }
    },
    isActive: function (page) {

      return this.current_page === page ? 'active' : '';

    },

    setActive: function (menu) {

      this.current_menu = menu;

    },

    initMqtt: function () {

      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;


      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      console.log('at endpoint ' + endpoint)

      var client = mqtt.connect(endpoint, options);

      client.on('connect', function () {

        console.log('connected here')

        if (vm.myProfile) {

          var profileTopic = 'topic/profile/' + vm.myProfile.id;
          profileTopic = profileTopic.toLowerCase();

          client.subscribe(profileTopic, function (err) {

            if (!err) {

              console.log('subscribed to topic ' + profileTopic);

            }

          });
        }

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      this.mqttClient = client;

    },

    initMenuModal: function () {

      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
        document.body.style.position = '';
      }

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        //document.body.style.position = 'fixed';
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("menu-modal")) {

          modal.style.display = "none";
        }

      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {

        if (event.target === modal) {

          modal.style.display = "none";

        }
      }

    },

    initDeposit: function () {

      this.dismiss();
      this.$router.push({ name: 'deposit' });
    },

    dismiss: function () {

      console.log('DISMISS MENU');
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";

    },

    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'sport-' + index + '-');

    },

    getSports: function () {
      this.$store.dispatch("getSports");
    },

    setAmount: function (amount) {

      this.amount = amount
      this.deposit();

    },

    deposit: function () {

      this.reset();
      var p = this.getProfile();

      if (!p) {

        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: 'login', params: { action: 'login' } });
        return;

      }

      if (this.amount < 1) {

        this.setError("Invalid Amount", "ENter amount atleast 1 KSH or above");
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");


      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: document.referrer,
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
        .then(res => {

          vm.loading = '';
          var msg = res.data.message;
          vm.setSuccess("Deposit Initiated", msg);
          vm.message = 'Please enter amount you want to deposit';

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            if (parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428) {

              vm.setError("Session Expired", "Your session on this device has expired");
              vm.logout();
              return;

            } else {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));
            }

          } else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          } else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })
    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },

    searchVisible: function () {

      console.log('this.current_page ==> ' + this.current_page);

      var searchVisible = this.current_page !== 'search';
      console.log('this.current_page ==> ' + this.current_page + ' searchVisible ==> ' + searchVisible);

      return searchVisible;

    },
    getCategories: function () {

      var vm = this;

      var path = "/category";

      casino.get(path)
        .then(res => {

          vm.categories = res.data;

        })
        .catch(err => {

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          } else if (err.request) {

            console.log(JSON.stringify(err.request));

          } else {

            console.log(JSON.stringify(err));

          }
        })
    },

  },
  data: function () {
    return {
      current_menu: '',
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: '',
      message: 'Please enter amount you want to deposit',
      categories: [],
    }
  },
  beforeDestroy: function () {

    console.log("beforeDestroy")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  }
}
</script>
